import React from "react";
import HeaderComponent from '../component/header/index'
import FooterComponent from '../component/footer/index'
import { Helmet } from "react-helmet";

export const PublicRoute = ({
    Component,
    title,
    keywords,
    description,
    showFooter
}) => {
    return (
        <div className="wrapper">
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
            </Helmet>
            <HeaderComponent />
            <div className="container_fluid">
                <div className="container">
                    <Component />
                </div>
            </div>
            <FooterComponent showFooter={showFooter} />
        </div>
    )

}
