import { connectStateResults } from 'react-instantsearch-dom';


const Results = (search) => {
  const { searching, searchState, searchResults } = search
 
 

  return (searching && `Searching...`) || (searchResults && searchResults.nbHits === 0 && `No results for '${searchState.query}'`)
};
export default connectStateResults(Results);