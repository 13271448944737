import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from '@emotion/styled'; 
import { IoIosSearch } from 'react-icons/io';

const SearchIcon = styled.div`
  width: 25px;
  pointer-events: none;
  margin-right: 10px;
  position: absolute;
  left: 8px;
  top:0px; 
  font-size:25px; 
  color:rgba(110, 176, 36, 1);
`;


 
const Input = styled.input`

  width:100%;
  border-radius: 4px;
  height: 36px;
  padding: 0 45px;
  font-size:16px;
  border:rgba(123, 193, 46, 1) 2px solid;
  color: rgba(32, 32, 34, 1);
  background:rgba(250, 251, 248, 1);
  ::-webkit-input-placeholder {
    color: rgba(32, 32, 34, 1);
    opacity:1;
  }
  :-moz-placeholder {
    color: rgba(32, 32, 34, 1);
    opacity:1;
  }
  ::-moz-placeholder {
    color: rgba(32, 32, 34, 1);
    opacity:1;
  }
  :-ms-input-placeholder {
    color: rgba(32, 32, 34, 1);
    opacity:1;
  }
`;

 
const SearchWrapper = styled.div`
 
  flex:1; 
`

const SearchRelative = styled.div`
  position:relative;
`

 

const Form = styled.form`
  display: flex;
  align-items: center;
 
  @media only screen and (max-width: 767px) {
    width: 100%; 
  }
`;

export default connectSearchBox(({ refine, ...rest }) => {
  
  const preventSubmit = e => {
    e.preventDefault();
  };

  const url = window.location.search 
  let searchParams = ''
  if(url) {
    searchParams = (((url.split('=').reverse())[0]).trim()).replaceAll('%20',' ');
  }

  return (
    <Form className={'formElement'} onSubmit={preventSubmit}>      
      <SearchWrapper> 
        <SearchRelative>
          <SearchIcon><IoIosSearch /></SearchIcon>
          <Input
            className={'searchInput '}
            autoComplete={'off'}
            defaultValue={searchParams}
            id="search_feild"
            type="text"
            placeholder="Search here"
            aria-label="Search"
            onChange={e => refine(e.target.value)}
            {...rest}
          />
        </SearchRelative>
      </SearchWrapper>
    </Form>
  );
});
