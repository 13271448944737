import React from "react"
import { connectHits, Snippet } from 'react-instantsearch-dom';
import { Highlight } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const SearchListing = styled.div`
  padding: 20px;
  border-radius:4px;
  color:#000000;
  background-color:#ffffff;
  margin-bottom: 10px;
  border:#ffffff 2px solid;
  &:hover {
    background-color:#E5F0C6;
    border-color:#E5F0C6;
    color: #000000 !important;
    & a{
      color: #000000 !important;
    }    
  }
  & a{
    color:#000000;
    text-decoration:none
  }
  @media (max-width: 767px) {
    & > .ais-Snippet{
      display:none
    }
  }
`;

const SearchLinks = styled.div`
 font-size:16px;
 font-weight:700;
`;

const HideContent = styled.div`
 max-height:63px;
 overflow:hidden
`;


const Hits = ({ hits, onClick }) => {
  return (
    <>
      {hits.map((hit, index) => {
        const { slug, title } = hit
        if (title) {
          return (
            <SearchListing key={index}>
              <Link to={slug} onClick={onClick}>
                <SearchLinks>
                  <Highlight attribute="title" hit={hit} tagName="mark" />                  
                </SearchLinks>
              
              <HideContent><Highlight attribute="seo" hit={hit} tagName="mark" />   </HideContent> 
              <Snippet attribute="excerpt" hit={hit} tagName="mark" />
              </Link>
            </SearchListing>
          )
        }
        return null
      })}
    </>
  )
};
export default connectHits(Hits);