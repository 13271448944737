import React, { useState, useEffect, createRef } from 'react';
import {
  InstantSearch,
  Index,
  Configure
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { algoliaAppId, algoliaSearchKey } from '../../constant/config'
import styled from '@emotion/styled';
import Input from './input';
import CustomHits from "./Hits"
import Results from './Results.js'; 

const HitsWrapper = styled.div`
  background-color:#F5F9E8;
  width:100%;
  border:rgba(123, 193, 46, 1) 2px solid;
 
  @media only screen and (max-width: 991px) {
    width: 400px;
    max-width: 400px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3em;
    h3 {
      color: black; 
      padding: 0.1em 0.4em; 
    }
  }
 
`;

const OuterSearch = styled.div`

  
`;


const Root = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1em;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;



const useClickOutside = (ref, handler, events) => {

  if (!events) events = [`mousedown`, `touchstart`];
  const detectClickOutside = event =>
    ref && ref.current && !ref.current.contains(event.target) && handler();

  useEffect(() => {
    for (const event of events) document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events) document.removeEventListener(event, detectClickOutside);
    };
  });
};

const searchClient = algoliasearch(
  algoliaAppId,
  algoliaSearchKey
);



export default function SearchComponent({ indices, collapse, hidden }) {
  const ref = createRef();
  const [query, setQuery] = useState('');
  const [focus, setFocus] = useState(false);
  useClickOutside(ref, () => setFocus(false));
  const displayResult = query && query.length > 0 ? 'showResults' : 'hideResults';

  return (
 
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0] ? indices[0].name : ''}
        onSearchStateChange={({ query }) => setQuery(query)}
        root={{ Root, props: { ref } }}
      >
        <OuterSearch className={!hidden ? 'show_search' : ''}>
          <Input onFocus={() => setFocus(true)} {...{ collapse, focus }} />
        </OuterSearch>
        <HitsWrapper
          className={'hitWrapper ' + displayResult}
          show={query && query.length > 0 && focus}
           
          ref={ref}
        >
          {indices.map(({ name }) => {
            return (
              <Index key={name} indexName={name}>
                <Results />
                <CustomHits onClick={() => {
                  setFocus(false)
                }} />
              </Index>
            );
          })}          
        </HitsWrapper>
        <Configure hitsPerPage={20} />
      </InstantSearch>
   
  );
}
