import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { AndroidIcon, IosIcon, LinixIcon, WindowIcon, AppleIcon, ResberryIcon } from '../assets/index'

const LogoSliderBottomComponent = () => {
    return (
        <div className="container-fluid logo_slider">
            <div className="container logo_fix">
                <div className="slider_btm">
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={30}
                        breakpoints={{
                            480: {
                                slidesPerView: 4,
                            },
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide><div className="icon_border"><img src={AndroidIcon} alt="" /></div></SwiperSlide>
                        <SwiperSlide><div className="icon_border"><img src={IosIcon} alt="" /></div></SwiperSlide>
                        <SwiperSlide><div className="icon_border"><img src={LinixIcon} alt="" /></div></SwiperSlide>
                        <SwiperSlide><div className="icon_border"><img src={WindowIcon} alt="" /></div></SwiperSlide>
                        <SwiperSlide><div className="icon_border"><img src={ResberryIcon} alt="" /></div></SwiperSlide>
                        <SwiperSlide><div className="icon_border"><img src={AppleIcon} alt="" /></div></SwiperSlide>
                    </Swiper>
                </div>
                <div className="edge_btns">
                    <Link to={'/downloads/'}>download edgeEngine</Link>
                    <Link to={'/hec-community/'}>join the community</Link>
                </div>
            </div>
        </div>
    )
}

export default LogoSliderBottomComponent