import React from "react"
export const ErrorIcon = () => {
    return (
        <svg width="421" height="421" viewBox="0 0 421 421" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.7693 60.303C55.2449 67.8261 55.2449 80.0243 62.7693 87.5474C70.2938 95.0706 82.4941 95.0706 90.0186 87.5474C97.543 80.0243 97.543 67.8261 90.0186 60.303C82.4941 52.7799 70.2938 52.7799 62.7693 60.303Z" fill="url(#paint0_linear_3932_1881)" />
            <path d="M192.611 19.2498C192.611 8.62314 201.236 0 211.884 0C222.512 0 231.137 8.62314 231.137 19.2498C231.137 29.8765 222.532 38.4997 211.884 38.4997C201.236 38.4997 192.611 29.8765 192.611 19.2498Z" fill="url(#paint1_linear_3932_1881)" />
            <path d="M332.728 90.0225C325.203 82.4993 325.203 70.3012 332.728 62.7781C340.252 55.255 352.453 55.255 359.977 62.7781C367.502 70.3012 367.502 82.4993 359.977 90.0225C352.453 97.5456 340.252 97.5456 332.728 90.0225Z" fill="url(#paint2_linear_3932_1881)" />
            <path d="M381.765 211.846C381.765 201.22 390.37 192.596 401.018 192.596C411.666 192.596 420.291 201.22 420.291 211.846C420.291 222.473 411.646 231.096 401.018 231.096C390.389 231.096 381.765 222.473 381.765 211.846Z" fill="url(#paint3_linear_3932_1881)" />
            <path d="M330.252 359.913C322.728 352.39 322.728 340.192 330.252 332.668C337.777 325.145 349.977 325.145 357.501 332.668C365.026 340.192 365.026 352.39 357.501 359.913C349.977 367.436 337.777 367.436 330.252 359.913Z" fill="url(#paint4_linear_3932_1881)" />
            <path d="M189.094 400.946C189.094 390.32 197.719 381.697 208.367 381.697C218.996 381.697 227.62 390.32 227.62 400.946C227.62 411.573 219.015 420.196 208.367 420.196C197.719 420.196 189.094 411.573 189.094 400.946Z" fill="url(#paint5_linear_3932_1881)" />
            <path d="M60.2942 357.438C52.7698 349.915 52.7698 337.717 60.2942 330.193C67.8187 322.67 80.019 322.67 87.5435 330.193C95.068 337.717 95.068 349.915 87.5435 357.438C80.019 364.961 67.8187 364.961 60.2942 357.438Z" fill="url(#paint6_linear_3932_1881)" />
            <path d="M0 208.35C0 197.723 8.60501 189.1 19.2532 189.1C29.8818 189.1 38.5065 197.723 38.5065 208.35C38.5065 218.977 29.8818 227.6 19.2532 227.6C8.62466 227.6 0 218.977 0 208.35Z" fill="url(#paint7_linear_3932_1881)" />
            <defs>
                <linearGradient id="paint0_linear_3932_1881" x1="-0.0392957" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
                <linearGradient id="paint1_linear_3932_1881" x1="-0.0392977" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
                <linearGradient id="paint2_linear_3932_1881" x1="-0.0392965" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
                <linearGradient id="paint3_linear_3932_1881" x1="-0.0393003" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
                <linearGradient id="paint4_linear_3932_1881" x1="-0.0392898" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
                <linearGradient id="paint5_linear_3932_1881" x1="-0.03929" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
                <linearGradient id="paint6_linear_3932_1881" x1="-0.0392965" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
                <linearGradient id="paint7_linear_3932_1881" x1="-0.0392923" y1="210.118" x2="420.251" y2="210.118" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E6352B" />
                    <stop offset="0.23" stopColor="#F29220" />
                    <stop offset="0.3" stopColor="#F3971D" />
                    <stop offset="0.38" stopColor="#F6A817" />
                    <stop offset="0.48" stopColor="#FCC30D" />
                    <stop offset="0.52" stopColor="#FFD109" />
                    <stop offset="0.75" stopColor="#ABC837" />
                    <stop offset="1" stopColor="#41ABB5" />
                </linearGradient>
            </defs>
        </svg>
    )
}