import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PublicRoute } from './public.route'
import RedirectRoute from './redirect.route'
const HomeLayout = React.lazy(() => import('../pages/home/index'))
const ComplementryListingLayout = React.lazy(() => import('../pages/complementaryCourses/courseListing'))
const HecCommunity = React.lazy(() => import('../pages/community/hecCommunity'))
const DiscussionGroup = React.lazy(() => import('../pages/community/discussionGroup'))
const SupportCommunity = React.lazy(() => import('../pages/community/supportCommunity'))
const WebAssembly = React.lazy(() => import('../pages/complementaryCourses/details/webAssembly'))
const EdgeCliLayout = React.lazy(() => import('../pages/complementaryCourses/details/edgeCli'))
const GetStartedWithMimik = React.lazy(() => import('../pages/complementaryCourses/details/getStartedWithMimik'))
const UnderstandingLinixContainer = React.lazy(() => import('../pages/complementaryCourses/details/understandingLinixContainer'))
const MdebugLayout = React.lazy(() => import('../pages/complementaryCourses/details/mDebug'))
const MdriveLayout = React.lazy(() => import('../pages/complementaryCourses/details/mDriveMicroservices'))
const DonwloadLayout = React.lazy(() => import('../pages/download/index'))
const PrivacyPolicy = React.lazy(() => import('../pages/infoPages/privacyPolicy'))
const DeveloperAgreement = React.lazy(() => import('../pages/infoPages/developerAgreement'))
const TermsOfUse = React.lazy(() => import('../pages/infoPages/termsUse'))
const Healthcheck = React.lazy(() => import('../pages/healthcheck'))
const PageNotFound = React.lazy(() => import('../pages/404/error'))


const CombineRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<PublicRoute Component={HomeLayout}
            title={'Develop Reliably and Scale Sustainably with mimik\'s Hybrid EdgeCloud Native Architecture'}
            keywords={'Hybrid Edge Cloud, edge-cloud native architecture, edge-native applications, edgeEngine,quick start guide, hands-on courses, sandbox environment, HEC community, edge microservices, WebAssembly binaries, source codes, mimik news, events, newsletter.'}
            description={'mimik\'s Hybrid Edge Cloud platform allows you to develop, deploy, and run your edge-native applications with ease. By leveraging the hybrid edgeCloud native architecture, you can deploy efficiently and scale sustainably across most platforms agnostically. Get started by creating an account and accessing member\'s only resources. Follow our quick start guide in the state of art developer documentation and learn the fundamentals through our hands-on courses. Install edgeEngine for your platform of choice and develop your projects in a sandbox environment. Join the community to explore resources and get support from members. Deploy your edge microservices and WebAssembly binaries, and contribute to the community by sharing your projects and source codes. Subscribe to our newsletter and stay updated on mimik news and events.'}
            showFooter={false}
          />} />
        <Route path="/complementary-courses"
          element={<PublicRoute Component={ComplementryListingLayout}
            title={'Gain Hands-On Understanding of Hybrid EdgeCloud Platform'}
            keywords={'mimik Academy, hands-on courses, hybrid edgeCloud platform, mimik technology, edgeCLI tool, edgeEngine, microservice, WebAssembly, mDrive microservice, Linux container architecture, video instructions, HEC certified courses, training opportunities, product demos.'}
            description={'Explore our hands-on courses and gain a better understanding of mimik technology. Learn how to install the mimik edgeCLI tool, run edgeEngine and deploy your first microservice, build and deploy WebAssembly module and binary, work with mimik\'s mDrive microservice, and understand Linux container architecture. Follow the free video instructions for a better learning experience. Contact us to receive more information regarding the Hybrid edgeCloud (HEC) certified courses, training opportunities, and product demos.'}
            showFooter={false}
          />} />
        <Route path="/hec-community"
          element={<PublicRoute Component={HecCommunity}
            title={'Join the Hybrid EdgeCloud Community'}
            keywords={'Hybrid EdgeCloud, HEC, community, GitHub, collaborative, communication, forum, contribute, insights, support, projects, issues, edgeEngine, discussion groups, Linux, TI, ARM, Standard Edition, Main child edition, QNX, Raspbian for ARM, MacOS.'}
            description={'Join the collaborative communication forum for the Hybrid EdgeCloud (HEC) platform and related projects. Contribute by requesting and providing insights/support for HEC-related projects and issues. Participate in the HEC GitHub community to stay updated and collaborate with other community members.'}
            showFooter={true}
          />} />
        <Route
          path="/community"
          element={<PublicRoute Component={DiscussionGroup}
            title={'edgeEngine Discussion Groups - Join the Community for Support'}
            keywords={'edgeEngine, discussion groups, community, support, standard edition, main-child edition, Android, iOS, TI, Linux, macOS, Windows, QNX, Raspbian for ARM.'}
            description={'Join the edgeEngine Discussion Groups to communicate with and receive support from the community regarding the edgeEngine product family. Different discussion groups are available for each edition of edgeEngine, including Standard Edition, Main-Child Edition, Android, iOS, TI, Linux, macOS, Windows, QNX, and Raspbian for ARM. Participate in discussions, request and provide support for edgeEngine-related projects and issues.'}
            showFooter={true}
         />} />
        <Route
          path="/support"
          element={<PublicRoute Component={SupportCommunity}
            title={'mimik Help & Support'}
            keywords={'mimik, help, support, documentation, edgeExpert, development, assistance.'}
            description={'Get help with your mimik projects by asking questions, exploring the documentation, or contacting an edgeExpert. You can also reach out to the mimik team for expert assistance in accelerating your development needs and projects.'}
            showFooter={true}
          />} />
        <Route
          path="/downloads"
          element={<PublicRoute Component={DonwloadLayout}
            title={'Download edgeEngine standard Edition and Main-child edition for a variety of operating systems. Android, Wimdows, Linux for ARM, ,  QNX, Raspbian for ARM, MacOS'}
            keywords={'edgeEngine, Linux, TI, ARM, Standard Edition, Main Child Edition, QNX, Raspbian for ARM, MacOS, download, installation, community resources.'}
            description={'Download edgeEngine family editions for various platforms, including Android, Wimdows, Linux for ARM, QNX, Raspbian for ARM, MacOS. Follow the instructions and navigate to GitHub to install edgeEngine on your preferred platform.'}
            showFooter={false}
          />} />
        <Route
          path="/deploying-a-web-assembly-microservice-under-edgeengine/:tab_name"
          element={<PublicRoute Component={WebAssembly}
            title={'mimik, WebAssembly microservice, deployment, intermediate level, step-by-step instructions, edgeEngine, tools, libraries, efficient, fast, programming, community, download edgeEngine, interactive learning environment, WASM, installing edgeEngine, creating a mimik account, deploying edgeEngine container, deploying edgeEngine image.'}
            keywords={'mimik, WebAssembly microservice, deployment, intermediate level, step-by-step instructions, pre-existing code, GitHub, edgeEngine, efficient, fast, programming, community, download edgeEngine.'}
            description={'Take your mimik skills to the next level and learn how to build and deploy a WebAssembly microservice that runs under edgeEngine in this intermediate-level course. Follow our step-by-step instructions and learn how to install edgeEngine and the necessary tools and libraries for building and compiling a WebAssembly module. Create a mimik account and project, and deploy the edgeEngine container and image. Exercise the WebAssembly microservice and experience the benefits of using WebAssembly for efficient and fast edgeEngine programming. Join our community and stay up to date with the latest mimik resources. Download edgeEngine and start building your own powerful microservices today.'}
            showFooter={false}
          />} />
        <Route
          path="/install-mimik-edgecli-tool/:tab_name"
          element={<PublicRoute Component={EdgeCliLayout}
            title={'Install the mimik CLI Tool Using the Instruqt UI'}
            keywords={'mimik, edgeCLI tool, installation, self-paced course, beginner level, 2 topics, 30 minutes, Instruqt UI, mimik services, code, interactive learning environment, community, download edgeEngine, edgeEngine Runtime. '}
            description={'Get started with mimik and learn how to install the mimik edgeCLI tool in this self-paced course. This beginner-level course includes 2 topics and takes approximately 30 minutes to complete. Follow our step-by-step instructions and learn how to work with mimik services in code by installing edgeEngine Runtime and the mimik command line tool  . Our interactive learning environment provides an easy-to-use UI for a hassle-free installation process. Join our community and stay up to date with the latest mimik resources. Download edgeEngine and start working with mimik services in code today.'}
            showFooter={false}
          />} />
        <Route
          path="/getting-up-and-running-with-mdebug/:tab_name"
          element={<PublicRoute Component={MdebugLayout}
            title={'Getting Up and Running with mDebug - Learn How to Debug Microservices'}
            keywords={'mDebug, microservice development, mServices ecosystem, web utility, Instruqt interactive learning environment.'}
            description={'This intermediate-level course teaches you how to implement the mDebug service for debugging microservices in the mServices ecosystem. You will learn how to get the mDebug web utility up and running from within the Instruqt interactive learning environment. Launch the course now to enhance your microservice development skills with mDebug.'}
            showFooter={false}
          />} />
        <Route
          path="/working-withmdrive-microservice/:tab_name"
          element={<PublicRoute Component={MdriveLayout}
            title={'Working with mDrive Microservice'}
            keywords={'mDrive, microservice, edgeEngine, edge device, REST API, file storage, metadata, serverless environment'}
            description={'Explore mDrive, a microservice that runs on edgeEngine instances hosted on edge devices. Learn how to use the REST API for file storage and metadata management in a serverless environment.'}
            showFooter={false}
          />} />
        <Route
          path="/edgeengine-and-microservices/:tab_name"
          element={<PublicRoute Component={GetStartedWithMimik}
            title={'Getting Started with mimik - Set Up Your First Introductory Microservice'}
            keywords={'mimik, edge technology, introductory microservice, self-paced course, beginner level,  edgeMicroservice, Raspberry Pi, cell phone, tablet, IoT device, interactive learning environment, hands-on experience, edgeExperts, documentation, updates.'}
            description={'Discover the basics of mimik edge technology with our self-paced course. This beginner-level course includes 11 topics and takes approximately 1 hour to complete. Follow our step-by-step instructions and create a simple edgeMicroservice that can run on devices such as Raspberry Pi, cell phones, tablets, or IoT devices. Our interactive learning environment provides a terminal window for you to execute instructions and gain hands-on experience. Get help from our edgeExperts or explore our documentation to enhance your learning experience. Sign up today to receive updates on mimik Academy resources.'}
            showFooter={false}
          />} />
        <Route
          path="/understanding-linux-container-architecture/:tab_name"
          element={<PublicRoute Component={UnderstandingLinixContainer}
            title={'Understanding Linux Container Architecture'}
            keywords={'Linux container architecture, binary executables, Docker container technology, containers, Linux operating system, Windows'}
            description={'This intermediate level course will cover the differences between binary executables and containers and provide an overview of Linux container architecture. The course aims to clear up common misunderstandings about container technology, particularly Docker container technology, and provide a better understanding of what a container is. The scope of the course is limited to the Linux operating system, but the concepts apply to Windows as well. '}
            showFooter={false}
          />} />
        <Route
          path="/privacy"
          element={<PublicRoute Component={PrivacyPolicy}
            title={'Privay Policy'}
            keywords={''}
            description={''}
            showFooter={true}
          />} />
        <Route
          path="/developer-agreement"
          element={<PublicRoute Component={DeveloperAgreement}
            title={'Developer Agreement'} keywords={''}
            description={''}
            showFooter={true}
          />} />
        <Route
          path="/terms-of-service"
          element={<PublicRoute Component={TermsOfUse}
            title={'Terms of Service | mimik edgeEngine License Agreement'}
            keywords={''}
            description={''}
            showFooter={true}
          />} />
        <Route
          path="/healthcheck"
          element={<Healthcheck />}
          title={'Health Check'}
          keywords={'Health Check'}
          description={'Health Check'}
          showFooter={true}
        />

        <Route
          path="/console/*"
          element={<RedirectRoute />}
        />

        <Route
          path="*"
          element={<PublicRoute Component={PageNotFound}
            title={'Oops! Page not found'}
            keywords={'Oops! Page not found'}
            description={'Oops! Page not found'}
            showFooter={true}
          />} />


      </Routes>
    </Router>
  )
}

export default CombineRoutes