import React from "react";
import styled from "styled-components";
import HubspotForm from 'react-hubspot-form'

const FeildLabel = styled.div`
color: #ffffff;
padding-bottom:12px;
`

const Label = styled.div`
    color: #7bc12e;
 
    padding: 0 0 10px 0;
    font-weight:400;
    font-size:16px;
`

const HubSpotCustomForm = styled.div`
  position:realative;
  & form {
    display:block; 
    position:relative;
    .hs_email > label{display:none}
    & .hs-form-field {
      .hs_error_rollup{display:none !important}
      .hs-error-msgs{
        color:#ffffff;
        font-size:14px;
      }

      
      
      input {
        border:none;
        border-radius: 4px 0 0 4px;
        padding: 8px 20px;
        height: 50px;
        text-align: left;
        width:100%;
        width:calc(100% - 100px);
        &:placeholder {
            color:#9D9D9D;
        }
      }
      
      & .hs-error-msgs{
        position:absolute
        font-size:13px;
        padding:0;
      }
      
      
    }

    & .hs_error_rollup{
        position:absolute;
        display:none;
        bottom:1px;
        left:0;
        color:#ffffff;
        font-size:13px;
      }
    
    & .hs-submit{
        position:absolute;
        right:0;
        top:0;
        & input {
            background-color: #7BC12E; 
            border-radius: 0 4px 4px 0;
            color: #fff;
            height:50px;
            border:none;
            padding: 0;
            font-weight:600;
            min-width:110px;
            &:hover{
                background-color: #6EB024;
                font-weight:700;
            }
            @media (max-width:400px) {
              padding: 0 5px;
              min-width:inherit;
            }
        }
      }
      
  }
`

const NewsletterComponent = () => {
    return (
        <div className="newsletter">
            <Label>subscribe to our newsletter</Label>
            <FeildLabel>your email address:</FeildLabel>
              <HubSpotCustomForm>
                <HubspotForm
                    portalId='3070981'
                    formId='43c2266c-52f4-4be0-a338-a1616435b872'
                    onSubmit={() => console.log('Submit!')}
                    onReady={() => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                />
            </HubSpotCustomForm>
        </div>
    )
}

export default NewsletterComponent