import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Logo, Menu } from '../../assets/index'
import { FaArrowLeft, FaChevronDown } from 'react-icons/fa';
import { IoCloseSharp, IoSearch } from 'react-icons/io5';
import { FiExternalLink } from "react-icons/fi";
import SearchComponent from '../search';
import { gatsby_index_name } from '../../constant/config'
import ScrollToTop from '../scrollTop';
import { DEVELOPER_MIMIK_URL } from '../../constant/path'

const HeaderComponent = () => {
    const [toggleSearch, setToggleSearch] = useState(false)
    const [toggleMenu, setToggleMenu] = useState(false)
    const [dropdown, toggleDropdown] = useState({
        training: false,
        community: false
    })
    const location = useLocation()
    const getActiveClass = (e) => {
        if (typeof e === 'string') {
            return location.pathname === e ? 'active' : ''
        } else {
            return e.includes(location.pathname) ? 'active' : ''
        }
    }
    const hideShowDropdown = (e) => {
        toggleDropdown({
            ...dropdown,
            [e]: !dropdown[e]
        })
    }
    const handleToggleMenu = (e) => {
        let tagBdoy = document.getElementsByTagName('body')[0]
        if (toggleMenu) {
            tagBdoy.className = ''
        } else {
            tagBdoy.className = 'active_menu'
        }

        setToggleMenu(e)
    }
    useEffect(() => {
        setToggleSearch(false)
    }, [location.pathname])

    const handleSearch = () => {

        setToggleSearch(true)
        setTimeout(() => {
            if (document.getElementById("search_feild")) document.getElementById("search_feild").focus();
        }, 0)
    }

    return (
        <div className='container_fluid'>
            <ScrollToTop />
            <div className='container header_fix'>
                <div className='header_section'>
                    <div className='right_header'>
                        <div className='logo'>
                            <Link to={'/'}><img src={Logo} alt="Developer Portal Logo" /></Link>
                        </div>
                        <div className='navigation'>
                            <ul>
                                <li><Link to={'/'} className={getActiveClass('/')}>home</Link></li>
                                <li><Link to={'#'} className={getActiveClass([
                                    '/edgeengine-and-microservices/overview',
                                    '/edgeengine-and-microservices/hand-on-lab',
                                    '/complementary-courses/',
                                    '/install-mimik-edgecli-tool/overview',
                                    '/install-mimik-edgecli-tool/hand-on-lab',
                                    '/deploying-a-web-assembly-microservice-under-edgeengine/overview',
                                    '/deploying-a-web-assembly-microservice-under-edgeengine/instructions',
                                    '/deploying-a-web-assembly-microservice-under-edgeengine/hand-on-lab',
                                    '/getting-up-and-running-with-mdebug/overview',
                                    '/getting-up-and-running-with-mdebug/about-mdebug',
                                    '/getting-up-and-running-with-mdebug/hand-on-lab',
                                    '/working-withmdrive-microservice/overview',
                                    '/working-withmdrive-microservice/about-mdrive',
                                    '/working-withmdrive-microservice/hand-on-lab',
                                    '/understanding-linux-container-architecture/overview',
                                    '/understanding-linux-container-architecture/hand-on-lab',
                                    '/on-demand-courses/',])}>learn</Link>
                                    <ul>
                                        <li><Link to={'/complementary-courses/'}>complementary courses</Link></li>
                                        <li><a target='_blank' rel='noopener noreferrer' href='https://devdocs.mimik.com/tutorials'>tutorials <span><FiExternalLink style={{ marginBottom: 4 }} /></span></a></li>
                                    </ul>
                                </li>
                                <li><Link to={'/downloads/'} className={getActiveClass('/downloads/')}>downloads</Link></li>
                                <li><Link to={'#'} className={getActiveClass(['/hec-community/', '/community/', '/support/'])}>community</Link>
                                    <ul>
                                        <li><Link to={'/hec-community/'}>HEC community</Link></li>
                                        <li><Link to={'/community/'}>discussion groups</Link></li>
                                        <li><Link to={'/support/'}>support</Link></li>
                                    </ul>
                                </li>
                                <li><a target='_blank' rel='noopener noreferrer' href='https://devdocs.mimik.com/'>docs <span><FiExternalLink style={{ marginBottom: 4 }} /></span></a></li>
                            </ul>
                        </div>
                    </div>
                    {toggleMenu ? <div className='mobile_navigation show_on_mobile'>
                        <div className='back_icon'><span onClick={() => handleToggleMenu(!toggleMenu)}><FaArrowLeft /></span></div>
                        <div className='logo_mobile'>
                            <Link to={'/'}><img src={Logo} alt="Developer Portal Logo" /></Link>
                        </div>
                        <div className='mobile_menu'>
                            <ul>
                                <li><Link to={'/'} onClick={() => handleToggleMenu(!toggleMenu)}>home</Link></li>
                                <li className='parent' onClick={() => hideShowDropdown('training')}><Link to={'#'} className={dropdown.training ? 'active' : ''}>training <FaChevronDown /></Link>
                                    {dropdown.training ? <ul>
                                        <li><Link to={'/complementary-courses/'}>complementary courses</Link></li>
                                        <li><a target='_blank' rel='noopener noreferrer' href='https://devdocs.mimik.com/tutorials'>tutorials <span><FiExternalLink style={{ marginBottom: 4 }} /></span></a></li>
                                    </ul> : null}
                                </li>
                                <li><Link to={'/downloads/'} onClick={() => handleToggleMenu(!toggleMenu)} className={getActiveClass('/downloads/')}>downloads</Link></li>
                                <li className='parent' onClick={() => hideShowDropdown('community')}><Link to={'#'} className={dropdown.community ? 'active' : ''}>community  <FaChevronDown /></Link>
                                    {dropdown.community ? <ul>
                                        <li><Link to={'/hec-community/'} onClick={() => handleToggleMenu(!toggleMenu)}>HEC community</Link></li>
                                        <li><Link to={'/community/'} onClick={() => handleToggleMenu(!toggleMenu)}>discussion groups</Link></li>
                                        <li><Link to={'/support/'} onClick={() => handleToggleMenu(!toggleMenu)}>support</Link></li>
                                    </ul> : null}
                                </li>
                                <li><a target='_blank' rel='noopener noreferrer' href={'https://devdocs.mimik.com/'}>docs <span><FiExternalLink style={{ marginBottom: 4 }} /></span></a></li>
                            </ul>
                        </div>
                        <div className='mobile_btm_menu'>
                            <ul>
                                <li><a target='_blank' rel='noopener noreferrer' className='login_btn' href={DEVELOPER_MIMIK_URL + 'login'}>login</a></li>
                                <li><a target='_blank' rel='noopener noreferrer' className='get_btn' href={DEVELOPER_MIMIK_URL + 'create_account'}>get started</a></li>
                                <li><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/'>mimik website</a></li>
                                <li><a target='_blank' rel='noopener noreferrer' href='https://mimik.com/about-us/'>about us</a></li>
                            </ul>


                        </div>
                    </div> : null}
                    <div className="user_menu">
                        <span className='show_on_mobile' onClick={() => handleToggleMenu(!toggleMenu)}><img src={Menu} alt="" /></span>

                        {toggleSearch ? <div className='search_overlap'>
                            <div className='search_bg' onClick={() => setToggleSearch(false)}></div>
                            <SearchComponent
                                indices={[{
                                    name: gatsby_index_name,
                                    title: "Results",
                                    hitComp: "PageHit"
                                }]}
                                collapse={true}
                                hidden={true}
                            />
                            <a className='close_icon'><IoCloseSharp onClick={() => setToggleSearch(false)} /></a>
                        </div> : <a className='search_icon'><IoSearch onClick={() => handleSearch()} /></a>}
                        <a target='_blank' rel='noopener noreferrer' className='login_btn' href={DEVELOPER_MIMIK_URL + 'login'}>log in</a>
                        <a target='_blank' rel='noopener noreferrer' className='get_btn' href={DEVELOPER_MIMIK_URL + 'create_account'}>get started</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent